// extracted by mini-css-extract-plugin
export var askMeContainer = "testimonial-page-module--ask-me-container--xMDPZ";
export var askMeElement = "testimonial-page-module--ask-me-element--NCCcx";
export var askMeResponse = "testimonial-page-module--ask-me-response--oYEQJ";
export var card = "testimonial-page-module--card--2ADDd";
export var cardContainer = "testimonial-page-module--card-container--eiBI-";
export var cardDetails = "testimonial-page-module--card-details--ClWOx";
export var cardProsCons = "testimonial-page-module--card-pros-cons--nOq17";
export var consProsElement = "testimonial-page-module--cons-pros-element--w5wDH";
export var consTitle = "testimonial-page-module--cons-title--mgWUd";
export var informationContainer = "testimonial-page-module--information-container--6dbLz";
export var informationDescription = "testimonial-page-module--information-description--KSKTb";
export var informationSubtitle = "testimonial-page-module--information-subtitle--XFIn7";
export var informationTitle = "testimonial-page-module--information-title--Ao7aW";
export var prosTitle = "testimonial-page-module--pros-title--rtZsw";
export var texts = "testimonial-page-module--texts--1Zf2A";