import * as React from "react";
import * as styles from "./testimonial-page.module.css"
import "../i18n";
import Layout from "../components/Layout/Layout";
import {useTranslation} from "react-i18next";
import sanitizeHtml from "sanitize-html";

import iuovm_image from "/static/images/iuvom.png"
import check from "/static/icons/check-green.svg"
import cross from "/static/icons/cross.svg"
import Seo from "../components/shared/Seo";

export default function TestimonialPage() {
    const {t, i18n} = useTranslation();

    return (
        <Layout showNavbar={true} showFooter={true}>
            <Seo title={"Testimonial page"} description={"Find some testimonials about hiring master."} canonicalPath={"/testimonial-page"}/>
            <div className={`${styles.informationContainer}`}>
                <h3 className={`${styles.informationSubtitle} ${styles.texts}`}>{t('testimonial-page.information_section.subtitle')}</h3>
                <h1 className={`${styles.informationTitle} ${styles.texts}`}>{t('testimonial-page.information_section.title')}</h1>
                <p className={`${styles.informationDescription} ${styles.texts}`}>{t('testimonial-page.information_section.description')}</p>
            </div>
            <div className={styles.cardContainer}>
                <div className={styles.cardDetails}>
                    <div className={styles.cardDetailsLeft}>
                        {Array.from(t('testimonial-page.card_section.details')).map((detail) => {
                            return (
                                <p className={styles.texts} dangerouslySetInnerHTML={{__html: sanitizeHtml(detail)}}/>
                            )
                        })}
                    </div>
                    <img src={iuovm_image} alt={"IUVOM company"}/>
                </div>
                <div className={styles.cardProsCons}>
                    <div className={styles.card}>
                        <h6 className={`${styles.consTitle} ${styles.texts}`}>{t('testimonial-page.card_section.cons.title')}</h6>
                        {Array.from(t('testimonial-page.card_section.cons.elements')).map((element) => {
                            return (
                                <div className={styles.consProsElement}>
                                    <img src={cross} alt={"Cross"}/>
                                    <p>{element}</p>
                                </div>
                            )
                        })}
                    </div>
                    <div className={styles.card}>
                        <h6 className={`${styles.prosTitle} ${styles.texts}`}>{t('testimonial-page.card_section.pros.title')}</h6>
                        {Array.from(t('testimonial-page.card_section.pros.elements')).map((element) => {
                            return (
                                <div className={styles.consProsElement}>
                                    <img src={check} alt={"Check"}/>
                                    <p>{element}</p>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className={styles.askMeContainer}>
                {Array.from(t('testimonial-page.ask_me_section')).map((element) => {
                    return (
                        <div className={styles.askMeElement}>
                            <h6 className={styles.texts}>{element.title}</h6>
                            <div className={styles.askMeResponse} dangerouslySetInnerHTML={{__html: element.response}} />
                        </div>
                    );
                })}
            </div>
        </Layout>
    );
}
